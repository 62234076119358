// messages
const validationMessage = {
  required: "This field is required*",
  email: "Please enter a valid Email ID",
  phoneNo: "Please enter a valid Contact Number",
  uniqueCode: "Please enter a valid 4 character Unique Code",
  pinCode: "Please enter a valid Pincode",
  numberText: "Please enter a valid number & Whitespaces are not allowed.",
  abhyasiIdPattern: "Please enter a valid Abhyasi ID",
  panNoPattern: "Please enter a valid PAN Number",
  aadharPattern: "Please enter a valid Aadhar No",
  name: "Whitespaces are not allowed at the start & Special characters are not allowed.",
  whitespace: "Whitespaces/Special Char are not allowed at the start",
  alphabet: "Only alphabets are allowed",
  freeText: "Required Fields/ Invalid input",
  decimal: "Please enter a valid number & Whitespaces are not allowed",
  notAllowWhitespace: "Whitespaces are not allowed.",
  url: "Provide Valid URL",
  pnr: "Please enter a valid PNR",
  websiteName:
    "Please enter a valid website name. Only small case letters (a-z) and underscore (_) between letters is allowed.",
};

// validations
const validations = {
  required: {
    value: true,
    message: validationMessage.required,
  },

  name: {
    value:
      /^[^ <>'\\"/;^`~_=+|?!@#$%^&*()[\]{}][^<>'\\"/;^`~_=+|?!@#$%^&*()[\]{}]*$/,
    message: validationMessage.name,
  },
  pnr: {
    value: /^[A-Z]{2}-[A-Z0-9]{4}-[A-Z]{4}$/,
    message: validationMessage.pnr,
  },

  alphabet: {
    value:
      /^[^ <>'\\"/;^`~_=+|?!@#$%^&*()[\]{}0-9][^<>'\\"/;^`~_=+|?!@#$%^&*()[\]{}0-9]*$/,
    message: validationMessage.alphabet,
  },
  uniqueCode: {
    value: /^[^-\s][0-9A-Z]{3}$/,
    message: validationMessage.uniqueCode,
  },
  email: {
    value: /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
    message: validationMessage.email,
  },

  phoneNo: {
    value: /^[0-9]{10,14}$/,
    message: validationMessage.phoneNo,
  },

  pinCode: {
    value: /^[1-9]{1}[0-9]{2}\s?[0-9]{3}$/,
    message: validationMessage.pinCode,
  },

  numberText: {
    value: /^[0-9]*$/,
    message: validationMessage.numberText,
  },

  abhyasiId: {
    value: /^([a-zA-Z]{6}[0-9]{3}|[HABhab]{1}[0-9]{8})$/,
    message: validationMessage.abhyasiIdPattern,
  },

  panNo: {
    value: /^([A-Z]{5}[0-9]{4}[A-Z]{1})$/,
    message: validationMessage.panNoPattern,
  },

  aadhar: {
    value: /^[0-9]{12}$/,
    message: validationMessage.aadharPattern,
  },
  whiteSpace: {
    value: /^[^ >'\\"/;^`~_=+|?!@#$%^&*()[\]{}][^`~]*$/,
    message: validationMessage.whitespace,
  },
  freeText: {
    value: /^[^ ][^`~]*$/,
    message: validationMessage.freeText,
  },
  decimal: {
    value: /^(?![^0-9])[0-9]*(\.[0-9]+)?$/,
    message: validationMessage.decimal,
  },
  url: {
    value:
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
    message: validationMessage.url,
  },
  notAllowWhitespace: {
    value: /^[^\s]+$/,
    message: validationMessage.notAllowWhitespace,
  },
  websiteName: {
    value: /^[a-z][a-z-_]*[a-z]$/,
    message: validationMessage.websiteName,
  },
};

export default validations;
