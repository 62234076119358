import React, { useEffect, useRef, useCallback, useState } from "react";

import {
  HfnFirebaseAuth,
  signOut,
} from "@heartfulnessinstitute/react-hfn-profile";
import { Card } from "primereact/card";
import { useNavigate } from "react-router-dom";

// utils
import { setLoginData, isLoginAuth } from "utils/login";
import { lStorage } from "utils/storage";
import { getHomePath } from "utils/common";
import { axAppSRCM } from "services/base";
import axios from "axios";
import config from "assets/config";
import { FIREBASE_LOGIN } from "utils/constants";

function Login() {
  const navigate = useNavigate();
  const minimalParam = lStorage.get("minimalMode");
  const landingPage = lStorage.get("landingPage");
  const [loader, setLoader] = useState(false);
  const redirectPath = () => {
    let redirectTo = "";
    if (minimalParam?.authredirect)
      redirectTo = `/${minimalParam?.authredirect}`;
    else if (landingPage?.param?.pathname)
      redirectTo = `${landingPage?.param?.pathname}${landingPage?.param?.search}`;
    else redirectTo = getHomePath();
    lStorage.set("landingPage", {});
    if (isLoginAuth()) navigate(redirectTo);
    else signOut();
  };
  useEffect(() => {
    redirectPath();
  }, []);

  useEffect(() => {
    if (!isLoginAuth() && config.loginType !== FIREBASE_LOGIN) {
      document.querySelector("hfn-oneauth")?.triggerAuth();
    }
  }, []);

  const firebaseRef = useRef(null);

  const searchFirebaseId = (firebaseIdVal) => {
    setLoader(true);

    return axios
      .get(`${config.firebaseidSearchBaseUrl}/?firebase_uid=${firebaseIdVal}`)
      .then((res) => {
        const positionTypeCodes = res?.data || [];
        let positionTypeCode = "";
        if (
          positionTypeCodes &&
          positionTypeCodes.length > 0 &&
          Array.isArray(res?.data)
        ) {
          const rfPos = positionTypeCodes.find(
            (code) => code.position_type_code === "RF"
          );
          const zcPos = positionTypeCodes.find(
            (code) => code.position_type_code === "ZC"
          );
          const ccPos = positionTypeCodes.find(
            (code) => code.position_type_code === "CC"
          );
          positionTypeCode =
            rfPos?.position_type_code ||
            zcPos?.position_type_code ||
            ccPos?.position_type_code;
          return positionTypeCode;
        }
        return "";
      });
  };

  const autoLogin = async (myInfo, srcmToken) => {
    const positionCode = await searchFirebaseId(myInfo?.user_firebase_uid);
    if (Object.keys(myInfo).length !== 0) {
      const user = {};
      user.name = myInfo.name;
      user.email = myInfo.user_email;
      const allData = { ...myInfo, ...{ positionCode } };
      user.loginUser = allData;
      user.loginRole = myInfo.abhyas_stage;
      user.srcmToken = srcmToken;
      lStorage.set("authInfo", user);
      setLoginData(user);
      redirectPath();
    }
  };

  const processLogin = useCallback(({ myInfo }) => {
    setLoader(true);
    let clearIns;
    if (firebaseRef.current) {
      clearIns = firebaseRef.current.authFn.onAuthStateChanged(
        (firebaseUser) => {
          firebaseUser?.getIdToken().then((srcmToken) => {
            if (myInfo && Object.keys(myInfo).length > 0)
              autoLogin(myInfo, srcmToken);
            else {
              lStorage.set("authInfo", { srcmToken });
              axAppSRCM.get("/api/v2/me/").then((res) => {
                if (res?.data?.results.length > 0) {
                  const myInfoData = res?.data?.results[0];
                  autoLogin(myInfoData, srcmToken);
                }
              });
            }
          });
        }
      );
    }
    return clearIns ? clearIns() : "";
  }, []);

  return (
    <div className="login-section">
      {config.loginType === FIREBASE_LOGIN && (
        <div className="card-wrapper">
          <Card>
            <div className="loging-logo p-px-2 p-mx-5 p-mx-md-0 p-mb-2 p-text-center">
              <img src="/assets/top-logo.png" alt="heartfulness" />
            </div>
            <div className="loging-logo p-px-2 p-mx-5 p-mx-md-0 p-text-center hfnLogo">
              <img src="/assets/hfn-logo.svg" alt="heartfulness" />
            </div>
            <div className="login">
              <h2 className="title">Heartfulness Events Portal</h2>
              <HfnFirebaseAuth
                ref={firebaseRef}
                titleText=""
                doLogin={processLogin}
              />
              {loader && <h3 className="processing">Processing...</h3>}
            </div>
          </Card>
        </div>
      )}
    </div>
  );
}

export default Login;
